import { template as template_156d214437644a54a1f3c86ab9ec3d19 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_156d214437644a54a1f3c86ab9ec3d19(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
