import { template as template_198e570be9b14c74928866c48bad072c } from "@ember/template-compiler";
const SidebarSectionMessage = template_198e570be9b14c74928866c48bad072c(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
