import { template as template_acad3f9a94a3472390d78e25f44492fd } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_acad3f9a94a3472390d78e25f44492fd(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
